$topnav-height: 90px;

.top-header {
  align-items: center;
  background-color: $area-color-1;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  height: $topnav-height;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 100;

  h1 {
    margin: 0;
  }
}

article.main-content {
  margin-top: $topnav-height;
}
